"use server"
import React, {useEffect, useState} from "react";

import 'react-circular-progressbar/dist/styles.css';
import { buildStyles, CircularProgressbarWithChildren } from "react-circular-progressbar";

interface StepData {
    steps: number;
    mileage: number;
}

const StepDial = () => {
    const [steps, setSteps] = useState<number>(0);
    const [mileage, setMileage] = useState<number>(0);
    const [daysLeft, setDaysLeft] = useState<number>(0);
    const totalSteps = 1250000;

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('/api/days');
                const days = await response.json();

                // Calculate total steps and mileage
                let totalSteps = 0;
                let totalMileage = 0;

                days.forEach((day: StepData) => {
                    totalSteps += day.steps;
                    totalMileage += day.mileage;
                });

                setSteps(totalSteps);
                setMileage(totalMileage);
            } catch (err) {
                console.error('Failed to fetch data', err);
            }
        };

        fetchData();

        // Calculate days left until January 1, 2024
        const calculateDaysBetween = (startDate: Date, endDate: Date) => {
            const oneDay = 24 * 60 * 60 * 1000; // milliseconds in one day
            const diffInTime = endDate.getTime() - startDate.getTime();
            return Math.round(diffInTime / oneDay);
        };

        setDaysLeft(calculateDaysBetween(new Date(), new Date('2025-01-01')));
    }, []);

    const percentage = steps / totalSteps * 100;
    const stepsPerDay = ((totalSteps - steps) / daysLeft).toFixed(0);

    return (
        <div className="dial-container">
            <div className="dial">
                <CircularProgressbarWithChildren
                    value={percentage}
                    styles={buildStyles({
                        pathColor: `rgb(98, 6, 172)`,
                        trailColor: '#d6d6d6'
                    })}
                    strokeWidth={8.5}
                >
                    <div style={{ fontSize: '36px', marginBottom: '5px', color: '#934fd3' }}>{steps.toLocaleString()}</div>
                    <div style={{ fontSize: '16px', color: '#aaaaaa', lineHeight: 1.5 }}>of {totalSteps.toLocaleString()}<br />({(steps / totalSteps * 100).toFixed(1)}%)</div>
                    <div style={{ fontSize: '20px', marginBottom: '5px', marginTop: '10px', color: '#a56dd9' }}>{(totalSteps - steps).toLocaleString()} remaining</div>
                    <div style={{ fontSize: '16px', marginBottom: '5px', marginTop: '5px', color: '#b588df' }}>{Number(stepsPerDay).toLocaleString()} per day</div>
                    <div style={{ fontSize: '20px', marginTop: '10px', color: '#c5a4e4' }}>{mileage.toFixed(2)} miles</div>
                </CircularProgressbarWithChildren>
            </div>
        </div>
    );
}

export default StepDial;
